import React, { useState } from "react";
import { auth } from "../firebase";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [isGoogleSignIn, setIsGoogleSignIn] = useState(false);

  const handleGoogleSignIn = async () => {
    setError(null);
    setIsGoogleSignIn(true);
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      navigate("/home");
    } catch (error) {
      setError(error.message);
    } finally {
      setIsGoogleSignIn(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      if (isSignUp) {
        await createUserWithEmailAndPassword(auth, email, password);
      } else {
        await signInWithEmailAndPassword(auth, email, password);
      }
      navigate("/home");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-900 text-white">
      <h1 className="text-4xl mb-8">{isSignUp ? "Sign Up" : "Sign In"}</h1>
      <form onSubmit={handleSubmit} className="space-y-4 w-64">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full px-3 py-2 bg-gray-800 rounded"
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full px-3 py-2 bg-gray-800 rounded"
          required
        />
        <button
          type="submit"
          className="w-full bg-yellow-500 text-black py-2 px-4 rounded hover:bg-yellow-600"
        >
          {isSignUp ? "Sign Up" : "Sign In"}
        </button>
        <div className="w-full mt-4 flex justify-center">
          <button
            onClick={handleGoogleSignIn}
            className="bg-blue-500 text-black py-2 px-4 rounded hover:bg-blue-600"
          >
            {isSignUp ? "Sign up" : "Sign in"} with Google
          </button>
        </div>
      </form>
      <p className="mt-4">
        {isSignUp ? "Already have an account?" : "Don't have an account?"}
        <button
          onClick={() => setIsSignUp(!isSignUp)}
          className="ml-2 text-yellow-500 hover:text-yellow-600"
        >
          {isSignUp ? "Sign In" : "Sign Up"}
        </button>
      </p>
      {error && <p className="mt-4 text-red-500">{error}</p>}
    </div>
  );
};

export default SignIn;
