import React from "react";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const navigate = useNavigate();

  const handleOpenPremium = () => {
    navigate("/auth");
    console.log("open premium");
  };

  const handleContinueWithoutPremium = () => {
    navigate("/home");
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-900 text-white">
      <h1 className="text-4xl mb-8">Welcome to Don't Think Finance</h1>
      <div className="space-y-4">
        <button
          onClick={handleOpenPremium}
          className="bg-yellow-500 text-black py-2 px-4 rounded hover:bg-yellow-600"
        >
          Login for Premium Features
        </button>
        <button
          onClick={handleContinueWithoutPremium}
          className="bg-gray-700 text-white py-2 px-4 rounded hover:bg-gray-800"
        >
          Continue without Premium
        </button>
      </div>
    </div>
  );
};

export default LoginPage;
