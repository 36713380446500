import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import FinancialBreakdownPage from "./pages/FinancialBreakdownPage";
import LoginPage from "./pages/LoginPage";
import SignIn from "./pages/SignIn";
import { AuthProvider, useAuth } from "./contexts/AuthContext";

function HomePage() {
  const [step, setStep] = useState(1);
  const [pay, setPay] = useState("");
  const [payments, setPayments] = useState([{ amount: "", tag: "" }]);
  const navigate = useNavigate();

  const handlePaySubmit = (e) => {
    e.preventDefault();
    setStep(2);
  };

  const handleAddPayment = () => {
    setPayments([...payments, { amount: "", tag: "" }]);
  };

  const handlePaymentChange = (index, field, value) => {
    const newPayments = payments.slice();
    newPayments[index][field] = value;
    setPayments(newPayments);
  };

  const handleLifestyleSubmit = () => {
    setStep(3);
  };

  const handleLifestyleClick = (lifestyle) => {
    lifestyle = lifestyle.replace(/ +/g, "");
    navigate(`/${lifestyle.toLowerCase()}`, {
      state: { pay, payments },
    });
  };

  return (
    <div className="bg-gray-900 text-white font-sans min-h-screen flex flex-col justify-center items-center">
      <header className="text-center py-10">
        <h1 className="text-4xl font-bold">Don't Think Finance</h1>
        <p className="text-lg mt-2 text-gray-400">
          The only finance tool you need
        </p>
      </header>

      {step === 1 && (
        <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
          <h2 className="text-2xl font-semibold mb-6 text-center">
            Enter Your Bi-Weekly Pay
          </h2>
          <form onSubmit={handlePaySubmit} className="space-y-4">
            <div>
              <label htmlFor="pay" className="block text-sm font-medium">
                Bi-Weekly Pay ($)
              </label>
              <input
                type="number"
                id="pay"
                value={pay}
                onChange={(e) => setPay(e.target.value)}
                className="w-full px-4 py-2 mt-1 bg-gray-700 text-white border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md font-semibold"
            >
              Next
            </button>
          </form>
        </div>
      )}

      {step === 2 && (
        <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
          <h2 className="text-2xl font-semibold mb-6 text-center">
            Enter Your Monthly Payments
          </h2>
          {payments.map((payment, index) => (
            <div key={index} className="space-y-2 mb-4">
              <div>
                <label className="block text-sm font-medium">
                  Payment Amount
                </label>
                <input
                  type="number"
                  value={payment.amount}
                  onChange={(e) =>
                    handlePaymentChange(index, "amount", e.target.value)
                  }
                  className="w-full px-4 py-2 mt-1 bg-gray-700 text-white border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium">
                  Tag (optional)
                </label>
                <input
                  type="text"
                  value={payment.tag}
                  onChange={(e) =>
                    handlePaymentChange(index, "tag", e.target.value)
                  }
                  className="w-full px-4 py-2 mt-1 bg-gray-700 text-white border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>
          ))}
          <button
            onClick={handleAddPayment}
            className="w-full py-2 bg-green-500 hover:bg-green-600 text-white rounded-md font-semibold mb-4"
          >
            Add Another Payment
          </button>
          <button
            onClick={handleLifestyleSubmit}
            className="w-full py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md font-semibold"
          >
            Next
          </button>
        </div>
      )}

      {step === 3 && (
        <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
          <h2 className="text-2xl font-semibold mb-6 text-center">
            Choose Your Lifestyle
          </h2>
          <div className="space-y-4">
            <div className="p-4 rounded-md monk-mode-animation other-options">
              <button
                className="w-full py-2 bg-green-500 text-white rounded-md font-semibold"
                onClick={() => handleLifestyleClick("Monk Mode")}
              >
                Monk Mode
              </button>
            </div>
            <div className="p-4 rounded-md ghost-mode-animation other-options">
              <button
                className="w-full py-2 bg-gray-500 text-white rounded-md font-semibold"
                onClick={() => handleLifestyleClick("Ghost Mode")}
              >
                Ghost Mode
              </button>
            </div>
            <div className="p-4 rounded-md other-options">
              <button
                className="w-full py-2 bg-blue-500 text-white rounded-md font-semibold"
                onClick={() => handleLifestyleClick("Social Spender")}
              >
                Social Spender
              </button>
            </div>
            <div className="p-4 rounded-md other-options">
              <button
                className="w-full py-2 bg-purple-500 text-white rounded-md font-semibold"
                onClick={() => handleLifestyleClick("Quiet Luxury")}
              >
                Quiet Luxury
              </button>
            </div>
            <div className="p-4 rounded-md starboy-animation relative">
              <button
                className="w-full py-2 bg-yellow-500 text-white rounded-md font-semibold"
                onClick={() => handleLifestyleClick("Starboy")}
              >
                Starboy
              </button>
              <svg
                className="star"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M12 .587l3.668 10.432h10.98l-8.871 6.435 3.668 10.432-8.871-6.434-8.872 6.434 3.669-10.432-8.871-6.435h10.98z" />
              </svg>
              <svg
                className="star"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M12 .587l3.668 10.432h10.98l-8.871 6.435 3.668 10.432-8.871-6.434-8.872 6.434 3.669-10.432-8.871-6.435h10.98z" />
              </svg>
              <svg
                className="star"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M12 .587l3.668 10.432h10.98l-8.871 6.435 3.668 10.432-8.871-6.434-8.872 6.434 3.669-10.432-8.871-6.435h10.98z" />
              </svg>
              <svg
                className="star"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M12 .587l3.668 10.432h10.98l-8.871 6.435 3.668 10.432-8.871-6.434-8.872 6.434 3.669-10.432-8.871-6.435h10.98z" />
              </svg>
            </div>
            <div className="p-4 rounded-md flex-god-animation relative">
              <button
                className="w-full py-2 bg-red-500 text-white rounded-md font-semibold"
                onClick={() => handleLifestyleClick("Flex God")}
              >
                Flex God
              </button>
              <div className="money">💸</div>
              <div className="money">💸</div>
              <div className="money">💸</div>
              <div className="money">💸</div>
            </div>
          </div>
        </div>
      )}

      <footer className="text-center py-6 text-gray-500 mt-10">
        <p>&copy; 2024 Don't Think Finance. All rights reserved.</p>
      </footer>
    </div>
  );
}

function StarboyPageFn(isPremium = false) {
  return (
    <FinancialBreakdownPage
      percentOfMonthlyPayToSave={10}
      sloganText="You only live once, here’s how to manage your cash ⭐️ starboy ⭐️"
      title="Starboy Financial Breakdown"
      icon="✨"
      colorScheme={{
        investment: "#FF6384",
        savings: "#36A2EB",
        remaining: "#FFCE56",
      }}
      lifestyleDescription="Starboy: I use money lavishly on things that upgrade my aesthetic, such as clothing, jewelery, shoes, accessories, etc. I don't mind using my money if it means I'm upping my aura."
      isPremium={isPremium}
    />
  );
}

function MonkModePageFn(isPremium = false) {
  return (
    <FinancialBreakdownPage
      percentOfMonthlyPayToSave={95}
      sloganText="Minimalism is the key, invest wisely."
      title="Monk Mode Financial Breakdown"
      icon="🌿"
      colorScheme={{
        investment: "#4CAF50",
        savings: "#8BC34A",
        remaining: "#FFEB3B",
      }}
      lifestyleDescription="Monkmode: I don't care about the things of this consumeristic society. I only care about things that are minimalistic. I like things like furniture, pottery, things that upgrade my home but not necessarily upgrade my lifestyle."
      isPremium={isPremium}
    />
  );
}

function FlexGodPageFn(isPremium = false) {
  return (
    <FinancialBreakdownPage
      percentOfMonthlyPayToSave={5}
      sloganText="Flex on them, but keep a little aside 💸"
      title="Flex God Financial Breakdown"
      icon="💸"
      colorScheme={{
        investment: "#F44336", // Bold red for power
        savings: "#FF7961", // Lighter red for energy
        remaining: "#FFCDD2", // Soft red for a touch of softness
      }}
      lifestyleDescription="Flexgod: I want to make it rain with my money. I want others to view me as a force that cannot be stopped at the club, at parties, and anywhere I go. I like fast cars, expensive watches, designer everything."
      isPremium={isPremium}
    />
  );
}

function QuietLuxuryPageFn(isPremium = false) {
  return (
    <FinancialBreakdownPage
      percentOfMonthlyPayToSave={30}
      sloganText="You got motion but on the DL, I bet you smell good 🍷"
      title="Quiet Luxury Financial Breakdown"
      icon="🌟"
      colorScheme={{
        investment: "#9C27B0", // Rich purple for luxury
        savings: "#CE93D8", // Lighter purple for sophistication
        remaining: "#E1BEE7", // Soft purple for grace
      }}
      lifestyleDescription="QuietLuxury: I love using my money on things that make me seem more elegant and chic. I love spending money on things that are good for me like chia seeds, pilates, expensive gym sessions, classes, etc. and I love clothing companies that are great quality but do not have flashy logos like polo's purple label, loro piana, bottega veneta, brunello cucinelli, etc."
      isPremium={isPremium}
    />
  );
}

function SocialSpenderPageFn(isPremium = false) {
  return (
    <FinancialBreakdownPage
      percentOfMonthlyPayToSave={15}
      sloganText="You worked hard this week, let's get lit this weekend 🎉"
      title="Social Spender Financial Breakdown"
      icon="🥂"
      colorScheme={{
        investment: "#FFC107", // Bright yellow for excitement
        savings: "#FFEB3B", // Even brighter yellow for vibrancy
        remaining: "#FFE082", // Soft yellow for balance
      }}
      lifestyleDescription="SocialSpender: I love going on nights out with my friends. I mostly buy food and drinks with my spare money but also social events like the club, bowling, movies, boba, meals on me, the mall, etc. I spend money when I'm out and about."
      isPremium={isPremium}
    />
  );
}

function GhostModePageFn(isPremium = false) {
  return (
    <FinancialBreakdownPage
      percentOfMonthlyPayToSave={80}
      sloganText="You play a different game of life, get that money up 📈"
      title="Ghost Mode Financial Breakdown"
      icon="👻"
      colorScheme={{
        investment: "#607D8B", // Dark gray-blue for mystery
        savings: "#B0BEC5", // Lighter gray for subtlety
        remaining: "#CFD8DC", // Very light gray for stealth
      }}
      lifestyleDescription="GhostMode: I'm locked in on my grind and hustle. I only use money if it has good potential on return on investment. I'm a startup entrepreneur boy who does not spare on coffee and tea though since I live at cafes to do work essentially. I don't look at the price tag on self help books and self help things in general but I do not go out to social events nor care about how I look."
      isPremium={isPremium}
    />
  );
}

function App() {
  // TODO: make this real premium state
  const { isAuthenticated } = useAuth() || {};
  const isPremium = isAuthenticated || false;

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/auth" element={<SignIn />} />
          <Route path="/starboy" element={StarboyPageFn(isPremium)} />
          <Route path="/monkmode" element={MonkModePageFn(isPremium)} />
          <Route path="/flexgod" element={FlexGodPageFn(isPremium)} />
          <Route path="/quietluxury" element={QuietLuxuryPageFn(isPremium)} />
          <Route
            path="/socialspender"
            element={SocialSpenderPageFn(isPremium)}
          />
          <Route path="/ghostmode" element={GhostModePageFn(isPremium)} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
