import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Bar, Line } from "react-chartjs-2";
import "chart.js/auto";
import { CalculateFutureValue } from "../utils/calculations.js";
import axios from "axios";
import { PacmanLoader } from "react-spinners";

function FinancialBreakdownPage({
  percentOfMonthlyPayToSave,
  sloganText,
  title,
  icon,
  colorScheme,
  lifestyleDescription,
  isPremium,
}) {
  const loaderOverride = {
    display: "block",
    margin: "0 auto",
    borderColor: "yellow",
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [recommendations, setRecommendations] = useState([]);
  const [years, setYears] = useState(10); // Default forecast for 10 years
  const location = useLocation();
  const navigate = useNavigate();

  const pay = location.state?.pay || 0;
  const payments = location.state?.payments || [];
  const monthlyPay = pay * 2;
  const totalPayments = payments
    .reduce((acc, payment) => acc + parseFloat(payment.amount || 0), 0)
    .toFixed(2);
  const payAfterPayments = monthlyPay - totalPayments;
  const savings = (
    payAfterPayments *
    (percentOfMonthlyPayToSave / 100)
  ).toFixed(2);
  const remaining = (payAfterPayments - savings).toFixed(2);
  const investment = (savings * 0.7).toFixed(2); // 70% of savings go to investments (e.g., VTI)
  const hysaSavings = (savings * 0.3).toFixed(2); // 30% of savings go to HYSA

  useEffect(() => {
    const getRecommendations = async () => {
      try {
        const response = await axios.post(
          "https://api.openai.com/v1/chat/completions",
          {
            model: "gpt-4-turbo",
            messages: [
              {
                role: "system",
                content:
                  "You are a financial assistant who provides shopping recommendations based on available budget and based on the lifestyle of the user. You only give a numbered list of 5 recommendations with a range of price next to each item and no explanation.",
              },
              {
                role: "user",
                content: `My lifestyle is described as follows ${lifestyleDescription}. I have ${remaining} dollars remaining after savings. What should I buy based on my money remaining and my lifestyle description based on the latest trends on social media and what people are buying right now?`,
              },
            ],
            max_tokens: 200,
            n: 1,
            temperature: 0.7,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            },
          },
        );

        setRecommendations(
          response.data.choices[0].message.content
            .split("\n")
            .filter((line) => line.trim() !== ""),
        );
      } catch (error) {
        console.error("Error fetching recommendations:", error);
      }
    };

    if (isPremium) {
      console.log("called");
      getRecommendations();
    } else {
      setDummyData();
    }
  }, [remaining, lifestyleDescription, isPremium]);

  const setDummyData = () => {
    setRecommendations(["buy premium 👀 - $5"]);
  };

  // Generate data for the line chart based on the selected number of years
  const labels = Array.from(
    { length: years },
    (_, i) => `${i + 1} year${i > 0 ? "s" : ""}`,
  );
  const vtiData = labels.map((_, i) =>
    CalculateFutureValue(investment * 12, 0.12, i + 1),
  );
  const hysaData = labels.map((_, i) =>
    CalculateFutureValue(hysaSavings * 12, 0.044, i + 1),
  );

  const lineData = {
    labels,
    datasets: [
      {
        label: "VTI Account Value",
        data: vtiData,
        fill: false,
        backgroundColor: colorScheme.investment,
        borderColor: colorScheme.investment,
        tension: 0.1,
      },
      {
        label: "HYSA Account Value",
        data: hysaData,
        fill: false,
        backgroundColor: colorScheme.savings,
        borderColor: colorScheme.savings,
        tension: 0.1,
      },
    ],
  };

  const barData = {
    labels: ["VTI Investment", "High-Yield Savings", "Remaining for use"],
    datasets: [
      {
        label: "Monthly Allocation",
        data: [investment, hysaSavings, remaining],
        backgroundColor: [
          colorScheme.investment,
          colorScheme.savings,
          colorScheme.remaining,
        ],
      },
    ],
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <div className="bg-gray-900 text-white font-sans min-h-screen flex flex-col justify-center items-center">
      {/* Hamburger Menu */}
      <div className="absolute top-0 left-0 p-4">
        <div
          className="w-8 h-8 bg-gray-700 rounded-full flex items-center justify-center cursor-pointer"
          onClick={toggleSidebar} // Toggle the sidebar open/close
        >
          <div className="space-y-1.5">
            <span className="block w-5 h-0.5 bg-white"></span>
            <span className="block w-5 h-0.5 bg-white"></span>
            <span className="block w-5 h-0.5 bg-white"></span>
          </div>
        </div>
      </div>

      {/* Sidebar */}
      <div
        className={`fixed top-0 left-0 h-full w-64 bg-gray-800 shadow-lg transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out z-50`}
      >
        <div className="flex flex-col p-4">
          <button
            className="text-white hover:bg-gray-700 rounded-md px-4 py-2 mb-4"
            onClick={() => {
              navigate("/home");
              closeSidebar();
            }}
          >
            🏠 Home
          </button>
          <button
            className="text-white hover:bg-gray-700 rounded-md px-4 py-2 mt-4"
            onClick={closeSidebar}
          >
            Close Side Bar
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="relative z-40 w-full max-w-2xl p-8 bg-gray-800 rounded-lg shadow-lg mt-20">
        <header className="text-center py-10">
          <h1 className="text-4xl font-bold">{title}</h1>
          <p className="text-lg mt-2 text-gray-400">
            {icon} {sloganText} {icon}
          </p>
        </header>

        <Bar data={barData} />

        <div className="mt-8">
          <h2 className="text-2xl font-semibold mb-4">Select Forecast Years</h2>
          <select
            value={years}
            onChange={(e) => setYears(Number(e.target.value))}
            className="bg-gray-700 text-white p-2 rounded"
          >
            {[10, 20, 30, 40, 50].map((yearOption) => (
              <option key={yearOption} value={yearOption}>
                {yearOption} years
              </option>
            ))}
          </select>
        </div>

        <div className="mt-8">
          <h2 className="text-2xl font-semibold mb-4">
            Future Value Forecast (over {years} years)
          </h2>
          <Line data={lineData} />
        </div>

        <div className="mt-8">
          <h2 className="text-2xl font-semibold mb-4">Your Payments</h2>
          <ul className="list-disc pl-5 space-y-2">
            {payments.map((payment, index) =>
              !payment.amount ? (
                "No payments going out!"
              ) : (
                <li key={index}>
                  ${payment.amount} {payment.tag ? `- ${payment.tag}` : "- msc"}
                </li>
              ),
            )}
          </ul>
        </div>

        <div className="mt-8">
          <h2 className="text-2xl font-semibold mb-4">AI Recommendations</h2>
          <ul className="list-disc pl-5 space-y-2">
            {recommendations.length < 1 ? (
              <PacmanLoader
                cssOverride={loaderOverride}
                aria-label="Loading Spinner"
                loading={recommendations.length < 1}
                color={"#FFFF00"}
              />
            ) : (
              recommendations.map((recommendation, index) => (
                <li key={index}>{recommendation}</li>
              ))
            )}
          </ul>
        </div>
      </div>

      <footer className="text-center py-6 text-gray-500 mt-10">
        <p>&copy; 2024 Don't Think Finance. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default FinancialBreakdownPage;
